var exports = {};

function cssToTree(tree, css, selector, prelude) {
  var declarations = {};
  var hasDeclarations = false;
  var key, value;

  for (key in css) {
    value = css[key];

    if (typeof value !== "object") {
      hasDeclarations = true;
      declarations[key] = value;
    }
  }

  if (hasDeclarations) {
    if (!tree[prelude]) tree[prelude] = {};
    tree[prelude][selector] = declarations;
  }

  for (key in css) {
    value = css[key];

    if (typeof value === "object") {
      if (key[0] === "@") {
        cssToTree(tree, value, selector, key);
      } else {
        var hasCurrentSymbol = key.indexOf("&") > -1;
        var selectorParts = selector.split(",");

        if (hasCurrentSymbol) {
          for (var i = 0; i < selectorParts.length; i++) {
            selectorParts[i] = key.replace(/&/g, selectorParts[i]);
          }
        } else {
          for (var i = 0; i < selectorParts.length; i++) {
            selectorParts[i] = selectorParts[i] + " " + key;
          }
        }

        cssToTree(tree, value, selectorParts.join(","), prelude);
      }
    }
  }
}

;
exports.cssToTree = cssToTree;
export default exports;
const _cssToTree = exports.cssToTree;
export { _cssToTree as cssToTree };